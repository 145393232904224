<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-11 22:48:02
 * @LastEditors: yuany
-->
<template>
  <div class="footer">
    <div class="container">
      <div class="footer-list">
        <router-link tag="p" to="/About" class="footer-item">
          关于
        </router-link>
        <router-link tag="p" to="/Service" class="footer-item">
          服务
        </router-link>
        <router-link tag="p" to="/Industry" class="footer-item">
          行业
        </router-link>
        <router-link tag="p" to="/Contact" class="footer-item">
          联系我们
        </router-link>
      </div>
      <div class="footer-copyright">
        <a href="https://beian.miit.gov.cn">
          蜀ICP备2021025358号
        </a>
        <br />
        ©2021 成都天合聚云科技有限公司 版权所有
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.footer {
  background: #2b2b2b;
  .container {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .footer-title {
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
    }
    .footer-list {
      color: rgb(153, 153, 153);
      font-size: 16px;
      line-height: 30px;
      display: flex;
      position: relative;
      p {
        cursor: pointer;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 15px;
        right: 15px;
        border-bottom: 1px solid #666666;
      }
      .footer-item {
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .footer-copyright {
      margin-top: 55px;
      font-size: 14px;
      line-height: 20px;
      color: #dcdcdc;
      font-weight: 500;
      a {
        color: inherit;
        font-weight: inherit;
        text-decoration: none;
      }
    }
  }
}
</style>
