<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-16 10:31:46
 * @LastEditors: yuany
-->
<template>
  <div class="container-title">
    <p class="m-b-sm m-t-none">{{ title }}</p>
    <span>
      {{ desc }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '智能APP运营, 助企业整合资源'
    },
    desc: {
      type: String,
      default: 'Intelligent APP operation'
    }
  }
}
</script>
<style lang="less" scoped>
.container-title {
  margin-top: 100px;
  font-weight: 500;
  margin-bottom: 70px;
  p {
    font-size: 28px;
    color: rgb(38, 38, 38);
    font-family: SourceHanSansCN-Regular;
  }
  span {
    color: #7a7b7b;
    font-size: 15px;
  }
}
</style>
