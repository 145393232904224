<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-16 22:03:39
 * @LastEditors: yuany
-->
<template>
  <img :src="imgUrl" alt="" />
</template>
<script>
export default {
  props: {
    imgUrl: {
      type: String,
      default: require('../assets/AD0I1-nNBhACGAAgtcGUyQUoy4GwxQIwgA84pgQ.jpg')
    }
  }
}
</script>
<style lang="less" scoped>
img {
  width: 100%;
  height: 550px;
  display: block;
}
</style>
