<!--
 * @Description: 服务保障
 * @Author: yuany
 * @Date: 2021-03-29 21:42:07
 * @LastEditors: yuany
-->
<template>
  <div class="container">
    <div class="assurance">
      <div
        class="assurance-item"
        v-for="(item, index) in assuranceList"
        :key="index"
      >
        <div
          class="assurance-title wow animate__slideInRight"
          data-wow-duration="1s"
        >
          <img :src="item.img" alt="" />
          <p>{{ item.title }}</p>
        </div>
        <div
          class="assurance-desc wow animate__slideInLeft"
          data-wow-duration="1s"
        >
          {{ item.desc }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      assuranceList: [
        {
          img: require('../assets/assurance-1.png'),
          title: '标准化流程',
          desc: '严格达成SLA，讲究人性化的服务软技巧，提高客户满意度'
        },
        {
          img: require('../assets/assurance-2.png'),
          title: '定期回访',
          desc:
            '统一服务流程、关键服务提醒和及时响应、非现场的满意度回访调查、现场抽查'
        },
        {
          img: require('../assets/assurance-3.png'),
          title: '方案灵活',
          desc: '提供驻场服务、离岸服务，驻场+离岸混合服务'
        },
        {
          img: require('../assets/assurance-4.png'),
          title: '极速响应',
          desc: '5*8小时工作时间故障解决，7*24小时非工作时间故障解决'
        },
        {
          img: require('../assets/assurance-5.png'),
          title: '技术雄厚',
          desc: '80%以上技术人员为中高级开发工程师，有丰富的项目经验。'
        },
        {
          img: require('../assets/assurance-6.png'),
          title: '费用透明',
          desc:
            '我们根据客户需要的服务内容，时间不同，提供不同形式的外包服务，经济实惠，费用透明'
        }
      ]
    }
  }
}
</script>
<style lang="less">
.assurance {
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  .assurance-item {
    flex: 0 0 33%;

    .assurance-title {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: block;
        width: 55px;
      }
      p {
        margin: 0;
        margin-left: 10px;
        color: #000;
        font-weight: 500;
        line-height: 55px;
        box-sizing: border-box;
      }
    }
    .assurance-desc {
      padding: 20px 60px;
      font-size: 14px;
      line-height: 21px;
      color: #999999;
    }
  }
}
</style>
