<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-10 10:48:08
 * @LastEditors: yuany
-->
<template>
  <div class="data-management">
    <div class="container">
      <container-title
        title="IT外包服务"
        desc="IT outsourcing service"
        class="wow animate__slideInRight"
        data-wow-duration="0.8s"
      />
      <div class="data-management-icon">
        <div
          class="data-management-icon-item"
          v-for="(item, index) in dataManagementItem"
          :key="index"
        >
          <img
            :src="item.icon"
            alt=""
            class=" wow animate__slideInRight"
            data-wow-duration="0.8s"
          />
          <div class="wow animate__slideInRight" data-wow-duration="1s">
            <p class="m-b-md">
              {{ item.title }}
            </p>
            <span>{{ item.desc }}</span>
          </div>
        </div>
      </div>
      <example v-show="showExample" />
    </div>
    <div class="data-management-banner" v-show="showBanner">
      <p class="wow animate__slideInRight" data-wow-duration="1s">
        为企业量身定制高效方案
      </p>
      <span class=" wow animate__slideInRight" data-wow-duration="1s"
        >节省IT支出</span
      >
    </div>
  </div>
</template>
<script>
import containerTitle from './containerTitle'
import example from './example'
export default {
  props: {
    showBanner: {
      type: Boolean,
      default: true
    },
    showExample: {
      type: Boolean,
      default: true
    }
  },
  components: {
    containerTitle,
    example
  },
  data() {
    return {
      dataManagementItem: [
        {
          icon: require('../assets/AD0I1-nNBhAEGAAgq4qQyQUoy6P0_wEweDhQ.png'),
          title: '系统和应用开发',
          desc:
            'OA系统  |  客户关系管理  |  网站  |  电商平台  |  工作流程管理  |  金融科技  |  云平台  |  ERP'
        },
        {
          icon: require('../assets/AD0I1-nNBhAEGAAgsYqQyQUomLbZqwYweDhQ.png'),
          title: '移动APP开发',
          desc:
            '证券类  |  教育类  |  电商类  |  社交类  |  旅游类  |  智慧医疗  |  游戏类  |  新闻类  |  O2O类'
        },
        {
          icon: require('../assets/AD0I1-nNBhAEGAAg3YqQyQUowOvOQjB4OFA.png'),
          title: '小程序、H5开发',
          desc:
            '微信小程序 |  H5活动页面  |  微分销  |  微信公众号  |  微官网  |  微支付 '
        }
      ]
    }
  }
}
</script>
<style lang="less">
.data-management {
  font-weight: 500;
  .data-management-icon {
    margin-bottom: 110px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .data-management-icon-item {
      flex: 0 0 25%;
      img {
        width: 120px;
      }
      p {
        margin-top: 50px;
        font-size: 22px;
        color: rgb(0, 0, 0);
      }
      span {
        line-height: 20px;
        font-size: 14px;
        color: #666666;
      }
    }
  }
  .data-management-banner {
    height: 305px;
    background: url(../assets/AD0I1-nNBhACGAAg89OTyQUovIXNkwEwgA84sQI.jpg)
        no-repeat top,
      transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    p {
      color: rgb(255, 255, 255);
      margin: 0;
      margin-right: 40px;
    }
    span {
      color: rgb(198, 198, 198);
      font-family: SourceHanSansCN-Regular;
      text-align: left;
      background-color: transparent;
    }
  }
}
</style>
