<template>
  <div class="service">
    <nav-bar />
    <banner :imgUrl="imgUrl" />
    <container-title
      title="主营业务"
      desc="Main Business"
      class="wow animate__slideInRight"
      data-wow-duration="1s"
    />
    <div class="container">
      <div class="service-list">
        <div
          class="service-item wow animate__slideInRight"
          data-wow-duration="1s"
          v-for="(item, index) in serviceList"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <p>{{ item.title }}</p>
          <!-- <span>Visual identity system</span> -->
        </div>
      </div>
    </div>
    <data-management :showBanner="false" :showExample="false" />
    <container-title
      title="人员外包服务"
      desc="Manpower outsourcing services"
      class="wow animate__slideInRight"
      data-wow-duration="1s"
    />
    <outsourcing />
    <container-title
      title="运维服务"
      desc="Operation and maintenance service"
      class="wow animate__slideInRight"
      data-wow-duration="1s"
    />
    <maintenance />
    <container-title
      title="服务保障"
      desc="Service assurance"
      class="wow animate__slideInRight"
      data-wow-duration="1s"
    />
    <assurance />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../components/nav'
import Footer from '../components/Footer'
import banner from '../components/banner'
import DataManagement from '../components/DataManagement'
import containerTitle from '../components/containerTitle'
import Outsourcing from '../components/Outsourcing'
import Maintenance from '../components/Maintenance'
import Assurance from '../components/Assurance'
export default {
  name: 'Home',
  components: {
    NavBar,
    Footer,
    banner,
    DataManagement,
    containerTitle,
    Outsourcing,
    Maintenance,
    Assurance
  },
  data() {
    return {
      imgUrl: require('../assets/AD0I1-nNBhACGAAg38iUyQUowOK64QUwgA84pgQ.jpg'),
      serviceList: [
        {
          img: require('../assets/service-5.png'),
          title: 'IT外包服务'
        },
        {
          img: require('../assets/service-6.png'),
          title: '人员外包服务'
        },
        {
          img: require('../assets/service-7.png'),
          title: '运维服务'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.service {
  .service-list {
    margin-bottom: 110px;
    display: flex;
    justify-content: space-between;
    .service-item {
      margin: 5px;
      img {
        width: 292px;
      }
      text-align: center;
      p {
        font-size: 18px;
        color: rgb(51, 51, 51);
        line-height: 20px;
        margin: 0;
        margin-top: 20px;
      }
      span {
        font-size: 12px;
        color: rgb(204, 204, 204);
        line-height: 17px;
      }
    }
  }
}
</style>
