<!--
 * @Description: 顶部导航栏
 * @Author: yuany
 * @Date: 2021-03-08 17:49:13
 * @LastEditors: yuany
-->
<template>
  <div class="nav-bar">
    <div class="container">
      <div class="icon">
        <img src="../assets/logo.png" alt="" />
        <p>成都天合聚云科技有限公司</p>
      </div>
      <div class="nav-list">
        <router-link
          tag="div"
          to="/"
          class="nav-item"
          :class="{ active: routeName === 'Home' }"
        >
          <p>首页</p>
          <span>HOME</span>
        </router-link>
        <router-link
          tag="div"
          to="/About"
          class="nav-item"
          :class="{ active: routeName === 'About' }"
        >
          <p>关于</p>
          <span>ABOUT</span>
        </router-link>
        <router-link
          tag="div"
          to="/Service"
          class="nav-item"
          :class="{ active: routeName === 'Service' }"
        >
          <p>服务</p>
          <span>SERVICE</span>
        </router-link>
        <router-link
          tag="div"
          to="/Industry"
          class="nav-item"
          :class="{ active: routeName === 'Industry' }"
        >
          <p>行业</p>
          <span>INDUSTRY</span>
        </router-link>
        <router-link
          tag="div"
          to="/Contact"
          class="nav-item"
          :class="{ active: routeName === 'Contact' }"
        >
          <p>联系我们</p>
          <span>CONTACT US</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    routeName() {
      return this.$route.name
    }
  }
}
</script>
<style lang="less" scoped>
.nav-bar {
  background: #2b2b2b;
  color: white;
  height: 120px;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 88px;
        display: block;
      }
      p {
        font-size: 28px;
        color: #fff;
        font-weight: 500;
        margin-left: 20px;
      }
    }
    .nav-list {
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item {
        margin-left: 15px;
        border-radius: 5px;
        width: 110px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 500;
        p {
          margin: 0;
        }
        span {
          display: none;
        }
        &.active,
        &:hover {
          cursor: pointer;
          color: #000;
          background: #ffffff;
        }
      }
    }
  }
}
</style>
