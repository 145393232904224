<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-16 21:48:40
 * @LastEditors: yuany
-->
<template>
  <div class="example">
    <div
      class="example-item wow animate__slideInRight"
      data-wow-duration="1s"
      v-for="(item, index) in exampleList"
      :key="index"
    >
      <p>
        <span>{{ item.value }}</span
        >{{ item.unit }}
      </p>
      <span>{{ item.desc }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      exampleList: [
        {
          value: 100,
          unit: '+',
          desc: '家行业优质客户'
        },
        {
          value: 50,
          unit: '+',
          desc: '行业案例'
        },
        {
          value: 100,
          unit: '+',
          desc: '项目全程管理'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.example {
  padding-top: 110px;
  padding-bottom: 110px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .example-item {
    flex: 1;
    position: relative;
    p {
      font-family: SourceHanSansCN-Regular;
      font-size: 26px;
      margin: 0;
      span {
        font-size: 56px;
        display: inline-block;
      }
    }
    > span {
      font-size: 16px;
      color: #666666;
    }
    &::after {
      position: absolute;
      content: '';
      height: 77px;
      top: 5px;
      right: 0;
      border-right: 1px solid #ccc;
    }
    &:last-child::after {
      content: none;
    }
  }
}
</style>
